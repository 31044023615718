import { useTheme, alpha, ThemeOptions, lighten } from '@mui/material/styles';

export const usePrimaryColor = (): string => useTheme().palette.primary.main;

export const TRANSITION_TIME = 250;
export const EASING_FUNCTION = 'cubic-bezier(0.4, 0, 0.2, 1)';
const makeTransition = (css: string): string =>
    `${css} ${TRANSITION_TIME}ms ${EASING_FUNCTION} 0ms`;
export const makeTransitions = (css: string[]): string =>
    css.map(makeTransition).join(', ');

export const SHADOW = '4px 8px 24px rgba(0, 0, 0, 0.15)';
export const HOVER_SHADOW = '4px 8px 24px rgba(0, 0, 0, 0.22)';

export const DEFAULT_SHADOW = {
    boxShadow: SHADOW,
    transition: makeTransitions(['box-shadow']),
    '&:hover': { boxShadow: HOVER_SHADOW },
};

export const COLLAPSED_SIDEBAR_WIDTH = '4.5rem';
export const EXPANDED_SIDEBAR_WIDTH = '13.5rem';
export type PaletteName =
    | 'manty'
    | 'colin'
    | 'kandinsky'
    | 'python'
    | 'office'
    | 'blues'
    | 'reds'
    | 'greens'
    | 'oranges'
    | 'yellows';

type Palette = { name: PaletteName; colors: string[]; gradient: string[] };

export const MANTY_COLOR_PALETTE: Palette = {
    name: 'manty',
    colors: [
        '#2185C5',
        '#7ECEFD',
        '#E9815F',
        '#F14F5B',
        '#6BBA90',
        '#F8BD4F',
        '#545BA8',
        '#A7C6E0',
        '#DFB582',
        '#199183',
        '#B33D5C',
        '#A26557',
        '#AF4D98',
        '#57DCD1',
        '#5786DC',
        '#784DAF',
    ],
    gradient: ['#6BBA90', '#EDDE5B', '#EAB55E', '#E95F5F'],
};

export const COLOR_PALETTES: Palette[] = [
    MANTY_COLOR_PALETTE,
    {
        name: 'colin',
        colors: [
            '#4163A5',
            '#F8BD50',
            '#5945AB',
            '#F88C50',
            '#A14658',
            '#329A8F',
            '#B43B99',
            '#8593C5',
            '#EB6D65',
            '#4680A1',
            '#7F889E',
            '#E74B6A',
            '#C2949D',
            '#47D691',
            '#8851A3',
            '#659AEB',
            '#CACACA',
        ],
        gradient: ['#339A8F', '#F8BD4F'],
    },
    {
        name: 'kandinsky',
        colors: [
            '#d2981a',
            '#a53e1f',
            '#457277',
            '#8dcee2',
            '#8f657d',
            '#47880A',
            '#2E9B9C',
            '#EDCE80',
        ],
        gradient: ['#8DCEE1', '#447277'],
    },
    {
        name: 'python',
        colors: [
            '#006B62',
            '#43AE76',
            '#CED748',
            '#E65116',
            '#58585A',
            '#228D6C',
        ],
        gradient: ['#ffd966', '#E65116'],
    },
    {
        name: 'office',
        colors: [
            '#5182BB',
            '#BE5150',
            '#9CB95F',
            '#8066A0',
            '#50ACC4',
            '#F5964F',
            '#1c4071',
            '#ece9dc',
        ],
        gradient: ['#4FACC4', '#8F657D'],
    },
    {
        name: 'blues',
        colors: [
            '#1a6a9d',
            '#2185c5',
            '#0d354e',
            '#4d9dd0',
            '#134f76',
            '#79b5dc',
        ],
        gradient: ['#79B5DC', '#1B6A9D'],
    },
    {
        name: 'reds',
        colors: [
            '#f3727b',
            '#c03f48',
            '#601f24',
            '#f14f5b',
            '#902f36',
            '#f6959c',
        ],
        gradient: ['#F6959C', '#902F36'],
    },
    {
        name: 'greens',
        colors: [
            '#406f56',
            '#88c7a6',
            '#2a4a39',
            '#6bba90',
            '#559473',
            '#a6d5bc',
        ],
        gradient: ['#A6D5BC', '#294A39'],
    },
    {
        name: 'oranges',
        colors: [
            '#ba674c',
            '#ed9a7e',
            '#5d3326',
            '#e9815f',
            '#8b4d39',
            '#f1b39f',
            '#8b4d39',
            '#f1b39f',
        ],
        gradient: ['#F1B39E', '#8B4D39'],
    },
    {
        name: 'yellows',
        colors: [
            '#ffd966',
            '#ffe8a3',
            '#99823d',
            '#ffe084',
            '#ccad51',
            '#ffefc1',
            '#ccad51',
            '#ffefc1',
        ],
        gradient: ['#FFE085', '#CCAD50'],
    },
];

// We use a constant dict to ensure that the same value will be mapped to the same colors
// across the platform.
export const COLOR_SCALES: Record<PaletteName, Palette['colors']> =
    COLOR_PALETTES.reduce(
        (acc, current) => ({ ...acc, [current.name]: current.colors }),
        {} as Record<PaletteName, Palette['colors']>,
    );

export const COLOR_GRADIENTS: Record<PaletteName, Palette['gradient']> =
    COLOR_PALETTES.reduce(
        (acc, current) => ({ ...acc, [current.name]: current.gradient }),
        {} as Record<PaletteName, Palette['gradient']>,
    );

export const PRIMARY_COLOR = '#2b5b91';
export const SECONDARY_COLOR = '#4cb5fc';
const BG_DARK = '#fafafa';
const BG_LIGHT = '#ffffff';
export const MANTY_BLACK = '#3F4750';
export const MANTY_ORANGE = '#E9815F';
export const MANTY_CYAN = '#006BBA';
const MANTY_DARK_BLUE = '#4063A5';
export const MANTY_LIGHT_GREY = '#EFEFEF';
export const MANTY_MEDIUM_GREY = '#899FB1';
const MANTY_DARK_GREY = '#3F4750';
export const MANTY_GREEN = '#529F5E';
export const MANTY_RED = '#F14F5B';

// be sure to include the font weights in the main.scss file
const BOLD_TEXT = {
    fontSize: 14,
    fontWeight: 700, // semi-bold
    color: MANTY_BLACK,
};
const SEMI_BOLD_TEXT = {
    fontSize: 14,
    fontWeight: 600, // semi-bold
    color: MANTY_BLACK,
};
export const LIGHT_TEXT = {
    fontSize: 14,
    fontWeight: 400, // light
    color: MANTY_BLACK,
};

const BODY = {
    fontWeight: 400, // light
    fontSize: 14,
};

export const THEME_BASE: ThemeOptions = {
    palette: {
        primary: {
            main: PRIMARY_COLOR,
            contrastText: '#ffffff',
            background: alpha(PRIMARY_COLOR, 0.15),
        },
        secondary: {
            main: SECONDARY_COLOR,
            background: lighten(SECONDARY_COLOR, 0.9),
        },
        bg: {
            light: BG_LIGHT,
            dark: BG_DARK,
        },
        error: {
            main: MANTY_RED,
            background: lighten(MANTY_RED, 0.9),
        },
        background: {
            default: MANTY_LIGHT_GREY,
            paper: BG_LIGHT,
        },
        warning: {
            main: MANTY_ORANGE,
            background: lighten(MANTY_ORANGE, 0.9),
        },
        info: {
            main: MANTY_DARK_BLUE,
            background: lighten(MANTY_DARK_BLUE, 0.9),
        },
        success: {
            main: MANTY_GREEN,
            background: lighten(MANTY_GREEN, 0.9),
        },
        text: {
            primary: MANTY_BLACK,
        },
        black: MANTY_BLACK,
        orange: MANTY_ORANGE,
        blue: MANTY_CYAN,
        darkBlue: MANTY_DARK_BLUE,
        lightGrey: MANTY_LIGHT_GREY,
        mediumGrey: MANTY_MEDIUM_GREY,
        darkGrey: MANTY_DARK_GREY,
        green: MANTY_GREEN,
        red: MANTY_RED,
        lightRed: alpha(MANTY_RED, 0.15),
        muiBlack: alpha('#000', 0.87),
    },
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiAccordionSummary: {
            styleOverrides: {
                expandIconWrapper: {
                    padding: '12px', // fix visual regression after MUI5 upgrade
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'inherit', // remove uppercase on all buttons
                    minHeight: '2.5rem',
                    fontWeight: 600, // semi-bold
                },
                contained: { boxShadow: 'none' },
                iconSizeMedium: {
                    '& > *:first-of-type': {
                        fontSize: 24,
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: ({ ownerState }) =>
                    ownerState.disabled && { border: 'none' }, // fix visual regression after MUI5 upgrade
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ ownerState }) =>
                    ownerState.startAdornment && {
                        paddingLeft: '6px', // fix visual regression after MUI5 upgrade
                    },
            },
        },
        MuiPaper: {
            defaultProps: {
                elevation: 4,
            },
            styleOverrides: {
                elevation4: {
                    boxShadow: '4px 8px 24px rgba(0, 0, 0, 0.06)',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '6px 12px', // fix visual regression after MUI5 upgrade
                },
            },
        },
        MuiTimelineContent: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    lineHeight: '16px',
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    backgroundColor: MANTY_BLACK,
                    color: 'white',
                    fontSize: '0.75rem',
                    padding: '0.5rem',
                    boxShadow: '4px 8px 8px rgba(0, 0, 0, 0.18)',
                },
            },
            defaultProps: {
                slotProps: {
                    popper: {
                        modifiers: [
                            {
                                name: 'computeStyles',
                                options: {
                                    adaptive: false, // Ensures stable positioning
                                    gpuAcceleration: false, // Fixes Chrome-specific positioning bugs
                                },
                            },
                        ],
                    },
                },
            },
        },
        MuiDialogTitle: {
            defaultProps: {
                variant: 'h5',
            },
        },
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: '14px',
                },
            },
        },
    },
    typography: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        // h1 and h2 have the same style so that we can use h1 only once per page,
        // and use h2 for other titles - without negotiating web semantics with our designers
        h1: { fontSize: 48 },
        h2: { fontSize: 34 },
        h3: { letterSpacing: 0.43, fontSize: 24, fontWeight: 600 },
        h4: { letterSpacing: 0.15, fontSize: 20, fontWeight: 600 },
        h5: { letterSpacing: 0.15, fontSize: 16, fontWeight: 600 },

        body1: BODY,
        body1Bold: BOLD_TEXT,
        body1SemiBold: SEMI_BOLD_TEXT,
        body2: { letterSpacing: 0.43, fontSize: 12, fontWeight: 400 },
        body2Bold: { letterSpacing: 0.43, fontSize: 12, fontWeight: 600 },
        body2SemiBold: { letterSpacing: 0.43, fontSize: 12, fontWeight: 600 },
    },
};
