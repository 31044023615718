import html2canvas from 'html2canvas';
import { TFunction } from 'i18next';
import { camelize } from 'helpers/syntaxHelper';
import { HOME_PAGE_URL } from 'components/App/components/routes';
import { DAYS, MONTHS, QUARTERS } from './dateHelper';

export function capitalize(text?: string | null): string {
    if (!text) {
        return '';
    }
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function capitalizeAndKeepTheRest(
    text?: string | null | number,
): string {
    if (!text) {
        return '';
    }
    return text.toString().charAt(0).toUpperCase() + text.toString().slice(1);
}

/**
 * Helper function downloading a file from the browser.
 * This may look like a hack but it's actually standard, HTML doesn't allow
 * the download of static file without a click on a <a> element.
 * See https://www.w3schools.com/TAgs/att_a_download.asp
 * https://stackoverflow.com/questions/14964035/how-to-export-javascript-array-info-to-csv-on-client-side
 * @param  {String} downloadURL URL of the item to download
 * @param  {String} title       name that will appear for the user
 * @param  {String} extension   extension of the file to download
 */
export function downloadFile(
    downloadURL: string,
    title: string,
    extension: string,
) {
    const a = document.createElement('a');
    const { body } = document;
    body.appendChild(a);
    a.style.display = 'none';
    a.className = `e2e-test-dl-${extension}`;
    a.href = downloadURL;
    a.download = `${title}.${extension}`;
    a.click();
    window.URL.revokeObjectURL(downloadURL);
    // we don't remove the element so that we can use e2e test to check that function works well
}

export function downloadPng(
    id: string,
    title: string,
    backgroundColor: 'transparent' | 'white' = 'transparent',
) {
    // UseCORS is used when exporting element coming from thir-party, for example google maps
    // TODO StrictNullCheck : Try to remove "!"
    html2canvas(document.getElementById(id)!, {
        backgroundColor,
        useCORS: true,
    }).then((canvas) => {
        downloadFile(canvas.toDataURL(), title, 'png');
    });
}

const regexAlphabet = /[^a-zA-Z]/g;
const regexNumber = /[^0-9]/g;

/**
 * Function sorting strings alphanumerically.
 * Code coming from stackoverflow.com/questions/4340227/sort-mixed-alpha-numeric-array
 * @param  {String} a
 * @param  {String} b
 * @return {Integer}
 */
export function sortAlphaNum(a: string | number, b: string | number): number {
    let stringA = String(a);
    let stringB = String(b);
    const aAlphabet = stringA.replace(regexAlphabet, '');
    const bAlphabet = stringB.replace(regexAlphabet, '');
    if (aAlphabet === bAlphabet) {
        if (aAlphabet !== '') {
            // Not a pure number, we want  to keep only the figures
            stringA = stringA.replace(regexNumber, '');
            stringB = stringB.replace(regexNumber, '');
        }
        const aNumber = parseFloat(stringA);
        const bNumber = parseFloat(stringB);

        if (aNumber === bNumber) {
            return 0;
        }
        return aNumber > bNumber ? 1 : -1;
    }
    return aAlphabet > bAlphabet ? 1 : -1;
}

/**
 * Check if arrayOne is a subset of arrayTwo
 * @param {Array} arrayOne
 * @param {Array} arrayTwo
 */
export function isSubset(arrayOne: string[], arrayTwo: string[]): boolean {
    if (arrayOne.length === 0) {
        return true;
    }
    return arrayOne.every((val) => arrayTwo.includes(val));
}

// We cannot use the .toISOString() default function, there is an issue with
// the time zone the offset all dates;
// When the argument is a number it is a timestamp
export const toISOFormat = (d: Date | number): string => {
    const date = d instanceof Date ? d : new Date(d);
    if (Number.isNaN(date)) {
        // The date is invalid
        return '';
    }
    const year = date.getFullYear();
    const month = `0${date.getMonth() + 1}`.slice(-2);
    const day = `0${date.getDate()}`.slice(-2);
    return `${year}-${month}-${day}`;
};

export const getFirstDayMonth = (d: Date): Date =>
    new Date(d.getFullYear(), d.getMonth(), 1);

export const getFirstDayOfYear = (d: Date): Date =>
    new Date(d.getFullYear(), 0, 1);

export function isDate(type?: DataTypesT | null): boolean {
    return !!type && ['date', 'datetime'].includes(type);
}

export function isDateOrYear(type?: DataTypesT | null): boolean {
    return !!type && ['date', 'datetime', 'year'].includes(type);
}

export const stringIsNumber = (obj: string): boolean => !Number.isNaN(+obj);

const sortDateNames = (dateList: string[], a: string, b: string): number =>
    dateList.indexOf(a) - dateList.indexOf(b);

export function sortMonthNames(a: string, b: string): number {
    return sortDateNames(MONTHS, a, b);
}

export function sortQuarterNames(a: string, b: string): number {
    return sortDateNames(QUARTERS, a, b);
}

export function sortDayNames(a: string, b: string): number {
    return sortDateNames(DAYS, a, b);
}

export function getTableName(field: string): string {
    return field.split('.')[0];
}

export function removeEmptyKeys(object: { [key: string]: unknown }): {
    [key: string]: unknown;
} {
    const response = { ...object };
    Object.keys(response).forEach(
        (key) =>
            (response[key] == null || response[key] === '') &&
            delete response[key],
    );
    return response;
}

// we need a custom values() function because flow type Object.values as mixed
// @ts-expect-error [TS migration] Was not detected by flow
export function values<A, B>(obj: Record<A, B>): Array<B> {
    return Object.keys(obj).map((key) => obj[key]);
}

export function copyToClipboard(str: string) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';

    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');

    document.body.removeChild(el);
}

// Ordering columns based on display index
// Metrics comes first, then dimensions, by display index
// This is used only when smart frontend is disabled
export const orderColumns = (
    columns: ReadonlyArray<ResponseColumnT>,
    queryDimensions: ReadonlyArray<QueryDimensionT | SentenceDimensionT>,
): ResponseColumnT[] => {
    const orderedColumns = columns.map((col) => {
        const dimension = queryDimensions.find((d) => d.field === col.key);
        return {
            ...col,
            originalKey: col.key,
            key: camelize(col.key),
            displayIndex: dimension ? dimension.displayIndex : null,
            isMetric: !dimension,
        };
    });
    orderedColumns.sort((a, b) => {
        if (a.isMetric) {
            return -1;
        }
        if (b.isMetric) {
            return 1;
        }
        if (a.displayIndex != null && b.displayIndex != null) {
            return a.displayIndex < b.displayIndex ? -1 : 1;
        }
        return 0;
    });

    return orderedColumns;
};

export const findSSOAuthAuth = (authMethods: string[]): string =>
    authMethods.find((authMethod) => authMethod.includes('sso')) || '';

export const includesSSO = (authMethods: string[]): boolean =>
    !!findSSOAuthAuth(authMethods);

export const normalizeString = (string: string): string =>
    string
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();

/**
 * Helper to turn an array of strings into a single string.
 * The result consists in the concatenation of all the elements in this array, separated by commas and spaces.
 * The final element of the array is separated from the other with an and operator.
 * @param  {UserInfoT} strings - List of strings
 * @param  {TFunction} t - Translation function from i18n
 */
export const joinArrayOfStrings = (
    strings: string[],
    t: TFunction,
): string | null => {
    const nbStrings = strings.length;
    const andLinker = t('sentence:linkers.and');
    switch (nbStrings) {
        case 0:
            return null;
        case 1:
            return strings[0];
        case 2:
            return `${strings[0]} ${andLinker} ${strings[1]}`;
        default: {
            const commaSeparatedFirstItems = strings
                .slice(0, nbStrings - 1)
                .join(', ');
            return `${commaSeparatedFirstItems} ${andLinker} ${
                strings[nbStrings - 1]
            }`;
        }
    }
};

export function getHomePageUrl(client: ClientT): string {
    return client.homePageUrl ?? HOME_PAGE_URL;
}

export const isTypeNumeric = (type: DataTypesT): boolean =>
    ['float', 'money', 'integer', 'fraction'].includes(type);
