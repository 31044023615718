/**
 * This is the i18n config for Storybook.
 * It loads the backend translations from the local server or from the remote server,
 * depending on the Chromatic URL.
 *
 * If we're happy with this feature, we should move the logic to CircleCI so Chromatic
 * builds the bundle with the right URL, similarly to the production i18n
 */

const frontLocales = ['components', 'sentence'];

const loadDevTranslationPath = (lng: string, namespace: string) =>
    `http://localhost:6006/locales/${lng}/${namespace}.json`;

const loadRemoteTranslationPath = (
    api: string,
    lng: string,
    namespace: string,
) =>
    frontLocales.includes(namespace)
        ? `/locales/${lng}/${namespace}.json`
        : `${api}/locales/${lng}/${namespace}`;

/**
 * Get the translations path by environment. Locally it gets files from localhost,
 * and when deployed on Chromatic it gets the files from the corresponding Manty API.
 */
const getTranslationsByEnv = (lngs: string[], namespaces: string[]) => {
    const chromaticUrlMatcher = window.location.href.match(
        /^(https:\/\/(develop-|develop|presentation|master))(.*)--.*(chromatic.com)/,
    );
    if (chromaticUrlMatcher) {
        const env = chromaticUrlMatcher[2];
        if (env === 'master')
            return loadRemoteTranslationPath(
                'https://mantyapi.eu',
                lngs[0],
                namespaces[0],
            );
        else if (env === 'presentation')
            return loadRemoteTranslationPath(
                'https://presentation.mantyapi.eu',
                lngs[0],
                namespaces[0],
            );
        let api;
        const flavour = chromaticUrlMatcher[3];
        switch (flavour) {
            case '':
                api = 'https://staging.mantyapi.eu';
                break;
            default:
                api = `https://${flavour}.staging.mantyapi.eu`;
        }
        if (!flavour) api = 'https://staging.mantyapi.eu';
        return loadRemoteTranslationPath(api, lngs[0], namespaces[0]);
    }
    return loadDevTranslationPath(lngs[0], namespaces[0]);
};

export const storybookConfig = {
    fallbackLng: ['fr', 'en'],
    ns: [
        'components',
        'blocks_manty',
        'blocks_manty_descriptions',
        'blocks_manty_values',
        'blocks_manty_short',
        'blocks_manty_software_CIRIL',
        'blocks_manty_software_ASTRE',
        'sentence',
        'backend',
    ],
    defaultNS: 'components',
    interpolation: {
        escapeValue: false,
    },
    backend: {
        allowMultiLoading: false,
        loadPath: getTranslationsByEnv,
    },
    react: {
        useSuspense: false,
    },
};
