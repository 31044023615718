export const HOME_PAGE_URL = '/home';
export const BUDGET_HOME_PAGE_URL = '/budget_home';
export const BUDGET_OVERVIEW = '/budget_overview';
export const HR_HOME_PAGE_URL = '/human_resources';
export const SUPPORT_PAGE_URL = '/support';
export const CHARTS_TEMPLATES_PATHNAME = '/charts_templates';
export const MAINTENANCE_PAGE_URL = '/maintenance';
export const DASHBOARDS_PAGE_URL = '/dashboards';

const routes = {
    activateAccount: '/activate_account/:token',
    resetPassword: '/reset_password/:token',
    maintenancePage: MAINTENANCE_PAGE_URL,
    supportBindTab: '/support/:bind/:tab',
    supportBind: '/support/:bind',
    support: SUPPORT_PAGE_URL,
    chartsTemplates: CHARTS_TEMPLATES_PATHNAME,
    explore: '/explore',
    exploreChart: '/explore/:chartKey',
    integrations: '/integrations',
    cellarFuncTable: '/cellar/:func/:table',
    cellarFunc: '/cellar/:func',
    cellar: '/cellar',
    profile: '/profile',
    adminTab: '/admin/:tab',
    adminBudget: '/budget_admin/:tab',
    initialisationBudget: '/budget_admin/initialisation/:initialisationId',
    mappings: '/mappings',
    dashboard: '/dashboard/:slug',
    dashboardPdf: '/dashboard/:slug/pdf',
    publicDashboard: '/public/dashboard/:slug',
    budgetProposal: '/budget_proposal/:budgetProposalId',
    budgetProposalByResource:
        '/budget_proposal/:budgetProposalId/resource/:resourceSoftwareId/:tab',
    budgetProposalArgument:
        '/budget_proposal/:budgetProposalId/resource/:resourceSoftwareId/arguments/:argumentId',
    budgetProposalArgumentPdf:
        '/budget_proposal/:budgetProposalId/resource/:resourceSoftwareId/arguments/:argumentId/pdf',
    budgetProposalExpenseOrIncome:
        '/budget_proposal/:budgetProposalId/resource/:resourceSoftwareId/:expenseOrIncome/:viewBy',
    budgetProposalProjection:
        '/budget_proposal/:budgetProposalId/resource/:resourceSoftwareId/projection/:projectionType',
    budgetHome: BUDGET_HOME_PAGE_URL,
    budgetOverview: '/budget_overview/:tab/:expenseOrIncome?/:projectionType?',
    operationView:
        '/budget_operation_view/:operationSoftwareId/:expenseOrIncome',
    homePage: HOME_PAGE_URL,
    dashboards: DASHBOARDS_PAGE_URL,
    indicators: '/indicators',
    hrSimulationHome: `${HR_HOME_PAGE_URL}/simulation_home`,
    hrSettings: `${HR_HOME_PAGE_URL}/settings`,
    hrPayStateHome: `${HR_HOME_PAGE_URL}/pay_state_home/:exercice`,
    hrPayStateTab: `${HR_HOME_PAGE_URL}/pay_state/:id/:tab`,
    hrPayState: `${HR_HOME_PAGE_URL}/pay_state/:id`,
    hrSimulationTab: `${HR_HOME_PAGE_URL}/simulation/:id/:tab`,
    hrSimulation: `${HR_HOME_PAGE_URL}/simulation/:id`,
    hrRetrospectiveHome: `${HR_HOME_PAGE_URL}/retrospective_home`,
    hrRetrospective: `${HR_HOME_PAGE_URL}/retrospective/:id`,
} as const;

export default routes;
