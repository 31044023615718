import { parse, stringify } from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * On a given string url, applies given transformation treatment (e.g. snakify)
 * on parts of the url indicated by the "indexes" array, parts of the URL being
 * determined by a split on the "/"
 */
export const transformUrl = (
    url: string,
    indexes: number[],
    transformCallback: (arg: string) => string,
): string =>
    url
        .split('/')
        .map((pathElem, index) =>
            indexes.includes(index) ? transformCallback(pathElem) : pathElem,
        )
        .join('/');

/**
 * On the given URL, applies a treatement (e.g. snakify) to all the values of the specified queryParams.
 * E.G : snakify all values for the queryparams key "metric" and "dimension"
 * This helper is mainly (but not exclusively) used by the getDataUrlMiddleware helper.
 */
export const transformUrlParams = (
    endpoint: string,
    paramsKeysToTransform: Array<string>,
    transformMethod: (text: string) => string,
): string => {
    const [url, queryParams] = endpoint.split('?');
    const splittedParams = parse(queryParams);

    Object.keys(splittedParams).forEach((key) => {
        if (!Array.isArray(splittedParams[key])) {
            splittedParams[key] = [splittedParams[key] as string];
        }
        if (paramsKeysToTransform.includes(String(key))) {
            splittedParams[key] = (splittedParams[key] as string[]).map(
                (value: string) => {
                    const [field, ...rest] = value.toString().split('|');
                    return [...[transformMethod(field)], ...rest].join('|');
                },
            );
        }
    });

    return `${url}?${stringify(splittedParams)}`;
};

export function useGetSearchParam(key: string, defaultValue: string): string;
export function useGetSearchParam(
    key: string,
    defaultValue?: string,
): string | undefined {
    const location = useLocation();
    const urlParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search],
    );
    return urlParams.get(key) ?? defaultValue;
}

export const getPublicToken = (): string | null => {
    const params = new URLSearchParams(window.location.search);
    return params.get('public_token');
};
