// Environment
export const IS_DEV = import.meta.env.VITE_NODE_ENV === 'development';
export const IS_PROD = import.meta.env.VITE_NODE_ENV === 'production';
export const IS_TEST = import.meta.env.VITE_NODE_ENV === 'test';
export const IS_STAGING = import.meta.env.VITE_NODE_ENV === 'staging';
export const IS_PRESENTATION = import.meta.env.VITE_NODE_ENV === 'presentation';
export const IS_E2E = window.location.host.includes('e2e-test-app');
export const IS_STORYBOOK = import.meta.env.VITE_NODE_ENV === 'storybook';
export const VISION_PATH = import.meta.env.VITE_BACKEND_URL;
export const CURRENT_ENV = import.meta.env.VITE_NODE_ENV;
export const FORCED_BIND = import.meta.env.VITE_FORCED_BIND;
export const FLAVOUR = import.meta.env.VITE_FLAVOUR;

// Licence and API keys
export const AG_GRID_KEY = import.meta.env.VITE_AG_GRID_KEY;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const VERSION = import.meta.env.VITE_VERSION;
export const AMPLITUDE_KEY = import.meta.env.VITE_AMPLITUDE_KEY;
export const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY;

// Misc
export const COMMIT_HASH = import.meta.env.VITE_COMMIT_HASH;
