import { ScopeT, UserT, YamlProtectionT } from 'business/definitions';

import { Dispatch } from 'redux';

export type SupportUserT = Pick<UserT, 'id' | 'email'>;
export type SupportDashboardT = {
    key: number;
    slug: string;
    title: string;
    isHome: boolean | null | undefined;
    isHomeModel: boolean | null | undefined;
    isSharedToOrganisation: boolean;
};

// TODO: add types for default, current and custom ScopesPerBind
type SupportState = {
    dashboardsPerBind: { [key: string]: Array<SupportDashboardT> };
    mappingsPerBind: { [key: string]: Array<string> };
    defaultProtectionsPerBind: { protections?: YamlProtectionT[] };
    customProtectionsPerBind: { protections?: YamlProtectionT[] };
    defaultScopesPerBind: { [key: string]: Array<string> };
    currentScopesPerBind: { scopes?: ScopeT[] };
    customScopesPerBind: { [key: string]: Array<string> };
    usersPerBind: { [key: string]: Array<SupportUserT> };
};
export type ExtractorT = {
    software: string;
    username: string;
    password: string;
    dsn: string;
};

const supportState: SupportState = {
    dashboardsPerBind: {},
    mappingsPerBind: {},
    defaultProtectionsPerBind: {},
    customProtectionsPerBind: {},
    defaultScopesPerBind: {},
    currentScopesPerBind: {},
    customScopesPerBind: {},
    usersPerBind: {},
};

const supportModel = {
    state: supportState,
    reducers: {
        setDashboardsOf(
            state: SupportState,
            payload: {
                bind: string;
                dashboards: Array<SupportDashboardT>;
            },
        ): SupportState {
            return {
                ...state,
                dashboardsPerBind: {
                    ...state.dashboardsPerBind,
                    [payload.bind]: payload.dashboards,
                },
            };
        },
        setMappingsOf(
            state: SupportState,
            payload: {
                bind: string;
                mappings: Array<string>;
            },
        ): SupportState {
            return {
                ...state,
                mappingsPerBind: {
                    ...state.mappingsPerBind,
                    [payload.bind]: payload.mappings,
                },
            };
        },
        setCustomProtections(
            state: SupportState,
            payload: SupportState['customProtectionsPerBind'],
        ): SupportState {
            return {
                ...state,

                customProtectionsPerBind: payload,
            };
        },
        setDefaultProtections(
            state: SupportState,
            payload: SupportState['defaultProtectionsPerBind'],
        ): SupportState {
            return {
                ...state,

                defaultProtectionsPerBind: payload,
            };
        },
        setCustomScopes(
            state: SupportState,
            payload: {
                customScopesPerBind: any;
            },
        ): SupportState {
            return {
                ...state,
                customScopesPerBind: payload,
            };
        },
        setCurrentScopes(
            state: SupportState,
            payload: SupportState['currentScopesPerBind'],
        ): SupportState {
            return {
                ...state,
                currentScopesPerBind: payload,
            };
        },
        setDefaultScopes(
            state: SupportState,
            payload: {
                defaultScopesPerBind: any;
            },
        ): SupportState {
            return {
                ...state,
                defaultScopesPerBind: payload,
            };
        },
    },
    effects: (dispatch: Dispatch) => ({
        async createPlatform(body: {
            name: string;
            bind: string;
            teamPassword: string;
            extractorPassword: string;
            logoUrl: string;
            primaryColor: string;
            secondaryColor: string;
            extractors: ExtractorT[];
            modules: string[];
        }) {
            await dispatch.API.post({
                params: {
                    body,
                    endpoint: `support/clients`,
                },
                // @ts-expect-error [TS migration] Was not detected by flow
                successAction: (response) => {
                    // @ts-expect-error [TS migration] Was not detected by flow
                    window.location =
                        `https://${body.bind}.manty.eu` +
                        `/explore?token=${response.token}`;
                },
                // @ts-expect-error [TS migration] Was not detected by flow
                failureAction: (status, response) => {
                    dispatch.vision.notifyUser({
                        message: Object.values(response.errors),
                    });
                },
            });
        },

        async fetchDefaultProtections() {
            const response = await dispatch.API.get({
                params: { endpoint: `support/default-protections/` },
            });

            this.setDefaultProtections(response.data);
        },

        async fetchCustomProtections({ bind }: { bind: string }) {
            const response = await dispatch.API.get({
                params: { endpoint: `support/${bind}/custom-protections/` },
            });

            this.setCustomProtections(response.data);
        },

        async updateCustomProtections({
            bind,
            protections,
        }: {
            bind: string;
            protections: any;
        }) {
            await dispatch.API.post({
                params: {
                    endpoint: `support/${bind}/custom-protections/`,
                    body: protections,
                },
            });
            await dispatch.support.fetchCustomProtections({ bind });
        },

        async fetchDefaultScopes() {
            const response = await dispatch.API.get({
                params: { endpoint: `support/default-scopes/` },
            });

            this.setDefaultScopes(response.data);
        },

        async fetchCurrentScopes({ bind }: { bind: string }) {
            const response = await dispatch.API.get({
                params: { endpoint: `support/${bind}/scopes/` },
            });

            this.setCurrentScopes(response.data);
        },

        async fetchCustomScopes({ bind }: { bind: string }) {
            const response = await dispatch.API.get({
                params: { endpoint: `support/${bind}/custom-scopes/` },
            });

            this.setCustomScopes(response.data);
        },

        async updateCustomScopes({
            bind,
            scopes,
        }: {
            bind: string;
            scopes: any;
        }) {
            await dispatch.API.post({
                params: {
                    endpoint: `support/${bind}/custom-scopes/`,
                    body: scopes,
                },
            });
            await dispatch.support.fetchCurrentScopes({ bind });
        },
    }),
};

export default supportModel;
